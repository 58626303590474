const categoryData = {
  "es": [
    {
      id: 1,
      title: 'Account Settings',
      icon: 'SettingsIcon',
      iconColor: 'text-primary',
      questions: [
        {
          id: 10,
          question: '¿Cómo cambio mi nombre de usuario?',
          slug: 'how-do-i-change-my-username',
        },
        {
          id: 11,
          question: '¿Puedo cambiar mi email?',
          slug: 'can-i-change-my-email',
        },
        {
          id: 12,
          question: '¿Cómo cambio mi contraseña?',
          slug: 'how-do-i-change-my-password',
        },
        {
          id: 13,
          question: '¿Cómo cambio el idioma?',
          slug: 'how-do-i-change-my-language',
        },
        {
          id: 14,
          question: '¿Cómo puedo gestionar mis dispositivos?',
          slug: 'how-do-i-handle-my-devices',
        },
        {
          id: 15,
          question: '¿Cómo puedo gestionar mis suscripciones?',
          slug: 'how-do-i-handle-my-subscriptions',
        },
      ],
    },
    {
      id: 2,
      title: 'Raices',
      icon: 'LinkIcon',
      iconColor: 'text-success',
      questions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-the-states-and-actions-mean-in-the-validation-table',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
    },
    {
      id: 3,
      title: 'School Groups',
      icon: 'UsersIcon',
      iconColor: 'text-warning',
      questions: [
        {
          id: 30,
          question: '¿Cómo creo un grupo?',
          slug: 'how-do-i-create-a-group',
        },
        {
          id: 31,
          question: '¿Cómo puedo ver y editar un grupo?',
          slug: 'how-can-i-see-and-edit-a-group',
        },
        {
          id: 32,
          question: '¿Cómo puedo borrar los grupos que ya no necesito?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
        },
        {
          id: 33,
          question: '¿Cómo asigno profesores a un grupo?',
          slug: 'how-do-i-asign-teachers-to-a-group',
        },
        {
          id: 34,
          question: '¿Cómo añado alumnos a un grupo?',
          slug: 'how-do-i-asign-students-to-a-group',
        },
        {
          id: 35,
          question: '¿Cómo descargo mi listado actual de grupos?',
          slug: 'how-do-i-download-a-list-of-my-groups',
        }
      ],
    },
    {
      id: 4,
      title: 'School Dreamers',
      icon: 'SmileIcon',
      iconColor: 'text-info',
      questions: [
        {
          id: 41,
          question: '¿Cómo creo un niño?',
          slug: 'how-do-i-create-a-dreamer',
        },
        {
          id: 42,
          question: '¿Cómo puedo ver y editar un niño?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
        },
        {
          id: 43,
          question: '¿Cómo puedo borrar los niños que ya no necesito?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
        },
        {
          id: 44,
          question: '¿Cómo asigno grupos a un niño?',
          slug: 'how-do-i-asign-groups-to-a-dreamer',
        },
        {
          id: 45,
          question: '¿Cómo asigno un correo electronico a un niño?',
          slug: 'how-do-i-asign-an-email-to-a-dreamer',
        },
        {
          id: 46,
          question: '¿Cómo descargo mi listado actual de niños?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
        }
      ],
    },
    {
      id: 5,
      title: 'School Structure',
      icon: 'GridIcon',
      iconColor: 'text-warning',
      questions: [
        {
          id: 51,
          question: '¿Cómo elimino la estructura de mi colegio?',
          slug: 'how-do-i-delete-school-structure',
        },
      ],
    }
  ],
  "en": [
    {
      id: 1,
      title: 'Account Settings',
      icon: 'SettingsIcon',
      iconColor: 'text-primary',
      questions: [
        {
          id: 10,
          question: 'How do I change my username?',
          slug: 'how-do-i-change-my-username',
        },
        {
          id: 11,
          question: 'Can I change my email?',
          slug: 'can-i-change-my-email',
        },
        {
          id: 12,
          question: 'How do I change my password?',
          slug: 'how-do-i-change-my-password',
        },
        {
          id: 13,
          question: 'How do I change my language?',
          slug: 'how-do-i-change-my-language',
        },
        {
          id: 14,
          question: 'How do I handle my devices?',
          slug: 'how-do-i-handle-my-devices',
        },
        {
          id: 15,
          question: 'How do I handle my subscriptions?',
          slug: 'how-do-i-handle-my-subscriptions',
        },
      ],
    },
    {
      id: 2,
      title: 'Raices',
      icon: 'LinkIcon',
      iconColor: 'text-success',
      questions: [
        {
          id: 20,
          question: 'How does Raíces integration works?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: 'What do I need before using Raíces integration?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: 'What do the states and actions mean in the validation table?',
          slug: 'what-do-the-states-and-actions-mean-in-the-validation-table',
        },
        {
          id: 24,
          question: 'Raíces Integration: User Manual',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
    },
    {
      id: 3,
      title: 'School Groups',
      icon: 'UsersIcon',
      iconColor: 'text-warning',
      questions: [
        {
          id: 30,
          question: 'How do I create a group?',
          slug: 'how-do-i-create-a-group',
        },
        {
          id: 31,
          question: 'How can I see and edit a group?',
          slug: 'how-can-i-see-and-edit-a-group',
        },
        {
          id: 32,
          question: 'How can I delete the groups I no longer need?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
        },
        {
          id: 33,
          question: 'How do I asign teachers to a group?',
          slug: 'how-do-i-asign-teachers-to-a-group',
        },
        {
          id: 34,
          question: 'How do I asign students to a group?',
          slug: 'how-do-i-asign-students-to-a-group',
        },
        {
          id: 35,
          question: 'How do I download a list of my groups?',
          slug: 'how-do-i-download-a-list-of-my-groups',
        }
      ],
    },
    {
      id: 4,
      title: 'School Dreamers',
      icon: 'SmileIcon',
      iconColor: 'text-info',
      questions: [
        {
          id: 41,
          question: 'How do I create a dreamer?',
          slug: 'how-do-i-create-a-dreamer',
        },
        {
          id: 42,
          question: 'How can I see and edit a dreamer?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
        },
        {
          id: 43,
          question: 'How can I delete the dreamer I no longer need?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
        },
        {
          id: 44,
          question: 'How do I asign groups to a dreamer?',
          slug: 'how-do-i-asign-groups-to-a-dreamer',
        },
        {
          id: 45,
          question: 'How do I asign an email to a dreamer?',
          slug: 'how-do-i-asign-an-email-to-a-dreamer',
        },
        {
          id: 46,
          question: 'How do I download a list of my dreamers?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
        }
      ],
    },
    {
      id: 5,
      title: 'School Structure',
      icon: 'GridIcon',
      iconColor: 'text-warning',
      questions: [
        {
          id: 51,
          question: 'How do I delete my school structure?',
          slug: 'how-do-i-delete-school-structure',
        },
      ],
    }
  ],
}

export default categoryData