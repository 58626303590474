<template>
  <div>
    <custom-breadcrumb
      :pageTitle="$t('Help')"
      :breadcrumb="[]"
    ></custom-breadcrumb>
    <!-- header -->
    <section id="faq-search-filter">
      <b-card
        no-body
        class="faq-search"
        :style="{
          backgroundImage: `url(${require('@/assets/images/banner/banner.png')})`,
        }"
      >
        <b-card-body class="text-center">
          <h2 class="text-primary">
            {{ $t("help.header_title") }}
          </h2>
          <b-card-text class="mb-2">
            {{ $t("help.header_desc") }}
          </b-card-text>
        </b-card-body>
      </b-card>
    </section>
    <!--/ header -->

    <!-- categories -->
    <div id="knowledge-base-category">
      <b-row class="match-height">
        <b-col v-for="(data, index) in kb" :key="index" md="4" sm="6">
          <b-card>
            <h6 class="kb-title">
              <feather-icon
                :icon="data.icon"
                size="20"
                class="mr-50"
                :class="data.iconColor"
              />{{ $t("help.category." + data.title) }}
              <!-- ({{ data.questions.length }}) -->
            </h6>
            <b-list-group class="list-group-circle mt-2">
              <b-list-group-item
                v-for="que in data.questions"
                :key="que.question"
                class="text-body"
                :to="{
                  name: 'faq-question',
                  params: { category: data.title, slug: que.slug, id: que.id },
                }"
              >
                {{ que.question }}
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <!--/ categories -->

    <!-- contact us -->
    <section class="faq-contact">
      <b-row class="mt-5 pt-75 text-center">
        <b-col cols="12">
          <h2>{{ $t("help.contact_us_title") }}</h2>
          <b-card-text class="mb-3">
            {{ $t("help.contact_us_desc") }}
          </b-card-text>
        </b-col>
        <b-col sm="6">
          <b-card class="shadow-none py-1 faq-contact-card">
            <b-avatar size="42" rounded variant="light-primary" class="mb-2">
              <feather-icon icon="PhoneCallIcon" size="18" />
            </b-avatar>
            <h4>+34 911736498</h4>
            <span class="text-body">{{ $t("help.contact_us_phone") }}</span>
          </b-card>
        </b-col>
        <b-col sm="6">
          <b-card class="shadow-none py-1 faq-contact-card">
            <b-avatar size="42" rounded variant="light-primary" class="mb-2">
              <feather-icon icon="MailIcon" size="18" />
            </b-avatar>
            <h4>support@smileandlearn.com</h4>
            <span class="text-body">{{ $t("help.contact_us_email") }}</span>
          </b-card>
        </b-col>
      </b-row>
    </section>
    <!--/ contact us -->
  </div>
</template>

<script>
import categoryData from "./categoryData";
import CustomBreadcrumb from "@/views/components/breadcrumb/CustomBreadcrumb.vue";
import {
  BListGroup,
  BListGroupItem,
  BCard,
  BCardBody,
  BForm,
  BInputGroup,
  BFormInput,
  BCardText,
  BInputGroupPrepend,
  BTabs,
  BTab,
  BImg,
  BRow,
  BCol,
  BAvatar,
} from "bootstrap-vue";
export default {
  components: {
    CustomBreadcrumb,
    BListGroup,
    BListGroupItem,
    BCard,
    BCardBody,
    BForm,
    BInputGroup,
    BFormInput,
    BCardText,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BImg,
    BRow,
    BCol,
    BAvatar,
  },
  data() {
    return {
      kb: [],
    };
  },
  created() {
    this.categoryData();
  },
  computed: {
    lang() {
      return this.$store.getters["user/getLang"];
    },
  },
  watch: {
    lang(newLang, oldLang) {
      this.categoryData();
    },
  },
  methods: {
    categoryData() {
      if (this.$store.getters["user/getLang"] == 'la') {
        this.kb = categoryData['es'];
      } else {
        if(categoryData[this.$store.getters["user/getLang"]]){
          this.kb = categoryData[this.$store.getters["user/getLang"]];
        } else {
          this.kb = categoryData['en']; //cambiar por defecto a 'en'
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-faq.scss";
@import "@core/scss/vue/pages/page-knowledge-base.scss";
</style>